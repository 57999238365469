import { ServerPoll } from '../data-types';
import { getStatusFromSchedule } from './dateTimeUtil';

export function transformPollDataForSearch(poll: ServerPoll): void {
    /** Needed to be able to search by status, prompt and choices*/
    poll.status = getStatusFromSchedule(
        poll.eligibility?.schedule?.start_date ?? '',
        poll.eligibility?.schedule?.expiration_date ?? '',
    );
    poll.promptValue = poll.prompt?.value;
    if (poll.choices) {
        poll.choice_1 = poll.choices[0]?.value;
        poll.choice_2 = poll.choices[1]?.value;
        poll.choice_3 = poll.choices[2]?.value;
        poll.choice_4 = poll.choices[3]?.value;
        poll.choice_5 = poll.choices[4]?.value;
    }
}
